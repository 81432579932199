import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ResourceCard = ({ resource: {
  displayImage,
  link,
  title
} }) => {
  const image = getImage(displayImage);

  return (
    <div className="col-12 col-md-4 my-4">
      <a
        className="text-decoration-none"
        href={link}
        target="_blank"
      >
        <div className="card">
          <div className="card-header">
            <GatsbyImage image={image} alt={title} />
          </div>
          <div className="card-body">
            <p className="h6">
              <strong>{title}</strong>
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ResourceCard;
