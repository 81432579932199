import React from "react";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const FeaturedResourceCard = ({ resource: { displayImage, link, title } }) => {
  const image = getImage(displayImage);
  const bgImage = convertToBgImage(image);

  return (
    <div className="col-12">
      <a href={link} target="_blank">
        <BackgroundImage
          Tag="div"
          className="featured-resource"
          {...bgImage}
          preserveStackingContext
        >
          <div className="frosted">
            <p className="h6">
              <strong>{title}</strong>
            </p>
          </div>
        </BackgroundImage>
      </a>
    </div>
  );
};

export default FeaturedResourceCard;
