import React from 'react'
import Layout from '../containers/Layout'
import SEO from '../containers/seo'
import { graphql } from 'gatsby'
import FeaturedResourceCard from '../components/resources/FeaturedResourceCard'
import ResourceCard from '../components/resources/ResourceCard'

export const query = graphql`
  query ResourcesPageQuery {
    allGraphCmsResources {
      nodes {
        id
        title
        link
        source
        type
        isFeatured
        createdAt
        updatedAt
        displayImage {
          gatsbyImageData
        }
      }
    }
  }
`

const resources = ({
  data: {
    allGraphCmsResources: { nodes: resources }
  }
}) => {
  const pageSEO = {
    title: 'Resources'
  }

  return (
    <Layout>
      <SEO pageSEO={pageSEO} />
      <div className='section'>
        <div className='container'>
          <div className='row my-5'>
            <div className='col-12 text-center'>
              <h1 className='h4'>Resources</h1>
            </div>
          </div>
          <div className='row'>
            {resources.map((resource) => {
              if (resource.isFeatured) {
                return <FeaturedResourceCard resource={resource} />
              } else {
                return <ResourceCard resource={resource} />
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default resources

export const Head = () => (
  <>
    <title>Resources | Food Rescue Locator</title>
  </>
)
